import React, { useEffect } from "react";
import { setDefaultOptions } from "date-fns";
import { Outlet } from "react-router-dom";
import { Header } from "@/components/header";
import { Footer } from "@/components/footer";

import { useRecoilValue } from "recoil";
import { localeState } from "@/atoms/locale";
import { Locale } from '@/consts/locales';

const Root = () => {
  const locale = useRecoilValue(localeState);

  useEffect(() => {
    setDefaultOptions({ locale: Locale[locale] });
  }, [locale])

  return (
    <main className="min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1 background-image">
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};

export default Root;
