import React from "react";

export const LocationIcon = React.forwardRef(
  (
    { ...other }: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#2F2F2F"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M8.0006 15.5C7.79176 15.4982 7.58559 15.4528 7.39531 15.3667C7.20503 15.2806 7.03483 15.1557 6.8956 15C5.0706 13 2.0006 9.195 2.0006 6.68C1.97789 5.06517 2.597 3.50733 3.72197 2.34861C4.84694 1.18989 6.3858 0.525012 8.0006 0.5C9.6154 0.525012 11.1543 1.18989 12.2792 2.34861C13.4042 3.50733 14.0233 5.06517 14.0006 6.68C14.0006 9.18 10.9306 12.975 9.1056 15.005C8.96593 15.1598 8.79553 15.2837 8.60527 15.3689C8.41501 15.4542 8.20907 15.4988 8.0006 15.5ZM8.0006 1.5C6.65144 1.52629 5.36769 2.0862 4.43053 3.05711C3.49336 4.02801 2.97918 5.33075 3.0006 6.68C3.0006 8.25 4.7356 11.11 7.6406 14.335C7.68724 14.3834 7.74316 14.4219 7.80502 14.4482C7.86687 14.4745 7.93339 14.488 8.0006 14.488C8.06781 14.488 8.13433 14.4745 8.19619 14.4482C8.25804 14.4219 8.31396 14.3834 8.3606 14.335C11.2656 11.11 13.0006 8.25 13.0006 6.68C13.022 5.33075 12.5078 4.02801 11.5707 3.05711C10.6335 2.0862 9.34976 1.52629 8.0006 1.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
      <path
        d="M8 9.5C7.40666 9.5 6.82664 9.32405 6.33329 8.99441C5.83994 8.66477 5.45543 8.19623 5.22836 7.64805C5.0013 7.09987 4.94189 6.49667 5.05765 5.91473C5.1734 5.33279 5.45912 4.79824 5.87868 4.37868C6.29824 3.95912 6.83279 3.6734 7.41473 3.55765C7.99667 3.44189 8.59987 3.5013 9.14805 3.72836C9.69623 3.95543 10.1648 4.33994 10.4944 4.83329C10.8241 5.32664 11 5.90666 11 6.5C11 7.29565 10.6839 8.05871 10.1213 8.62132C9.55871 9.18393 8.79565 9.5 8 9.5ZM8 4.5C7.60444 4.5 7.21776 4.6173 6.88886 4.83706C6.55996 5.05683 6.30362 5.36918 6.15224 5.73463C6.00087 6.10009 5.96126 6.50222 6.03843 6.89018C6.1156 7.27814 6.30608 7.63451 6.58579 7.91422C6.86549 8.19392 7.22186 8.3844 7.60982 8.46157C7.99778 8.53874 8.39992 8.49914 8.76537 8.34776C9.13082 8.19639 9.44318 7.94004 9.66294 7.61114C9.8827 7.28224 10 6.89556 10 6.5C10 5.96957 9.78929 5.46086 9.41421 5.08579C9.03914 4.71072 8.53043 4.5 8 4.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.1"
      />
    </svg>
  )
);
