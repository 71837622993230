import {useHttpClient} from '@/hooks/use-http-client';
import {OrganizationControllerApi} from '@/api/generated';

export const useOrganization = () => {
  const httpClient = useHttpClient();

  const controller = new OrganizationControllerApi(undefined, undefined, httpClient);

  const organizationMe = async () => {
    try {
      return (await controller.organizationMe()).data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    organizationMe
  }
};
