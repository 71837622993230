import {
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationEllipsis,
    PaginationNext,
    Pagination as UIKitPagination,
    PaginationPrevious
} from "@/ui-kit/pagination";
import { FC, useEffect, useMemo, useState } from "react";

type PaginationProps = {
    total: number;
    defaultCurrent?: number;
    onChange?: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({ total, defaultCurrent, onChange }) => {
    const [current, setCurrent] = useState(1);


    useEffect(() => {
        if (defaultCurrent) {
            setCurrent(defaultCurrent);
        }
    }, [defaultCurrent]);

    const handleChange = (page: number) => {
        setCurrent(page);
        if (onChange) {
            onChange(page);
        }
    };

    // genious shit provided by gpt, looks a little bit ugly but works as expected =/
    const paginationBody = useMemo(() => {
        const pageNumbers = [];

        if (current > 1) {
            pageNumbers.push(1);
        }

        if (current > 2) {
            pageNumbers.push('...');
        }

        if (current > 0 && current <= total) {
            pageNumbers.push(current);
        }

        if (current + 1 <= total) {
            pageNumbers.push(current + 1);
        }

        if (current + 2 < total) {
            pageNumbers.push('...');
        }

        if (current < total) {
            pageNumbers.push(total);
        }

        return pageNumbers.map((number, index) => (
            <PaginationItem key={index}>
                {number === '...' ? (
                    <PaginationEllipsis>...</PaginationEllipsis>
                ) : (
                    <PaginationLink
                        isActive={number === current}
                        onClick={() => handleChange(number as number)}
                    >
                        {number}
                    </PaginationLink>
                )}
            </PaginationItem>
        ));
    }, [total, current]);

    return (
        <UIKitPagination className="my-4">
            <PaginationContent>
                <PaginationItem>
                    {current > 1 && (
                        <PaginationPrevious onClick={() => handleChange(current - 1)} />
                    )}
                </PaginationItem>
                {paginationBody}
                <PaginationItem>
                    {current < total && (
                        <PaginationNext onClick={() => handleChange(current + 1)} />
                    )}
                </PaginationItem>
            </PaginationContent>
        </UIKitPagination>
    );
};
