import React from "react";

export const MakeIcon = React.forwardRef(
  (
    { ...other }: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => (
    <svg
      ref={ref}
      width="296"
      height="71"
      viewBox="0 0 296 71"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M39.6775 69V31.88C39.6775 20.36 37.3735 15.24 30.3335 15.24C22.6535 15.24 18.5575 21 18.5575 34.44V69H0.6375V2.43999H17.9175V13.32H18.5575C18.5575 13.32 23.0375 0.519997 36.4775 0.519997C47.2295 0.519997 52.9895 5.64 55.5495 13.448L55.6775 13.32H56.3175C56.3175 13.32 62.0775 0.519997 75.5175 0.519997C92.1575 0.519997 96.6375 12.68 96.6375 28.04V69H78.7175V31.88C78.7175 20.36 76.4135 15.24 69.3735 15.24C61.6935 15.24 57.5975 21 57.5975 34.44V69H39.6775ZM121.573 22.92H103.653C104.677 5.896 118.373 0.519997 133.733 0.519997C149.733 0.519997 162.533 6.28 162.533 23.56V69H144.613V56.2H143.973C143.973 56.2 139.877 70.92 122.853 70.92C110.693 70.92 101.733 65.288 101.733 53C101.733 40.584 111.973 34.952 122.981 32.136C134.117 29.32 144.613 28.424 144.613 21C144.613 15.752 140.773 12.68 133.733 12.68C126.053 12.68 121.829 15.752 121.573 22.92ZM144.613 35.464C141.669 38.152 135.653 39.56 130.533 40.84C124.773 42.248 119.909 45.192 119.909 51.08C119.909 56.2 123.493 59.4 129.253 59.4C141.413 59.4 144.613 45.32 144.613 35.464ZM168.888 69V2.43999H186.808V29.32L210.488 2.43999H231.608L206.52 28.424L232.888 69H211L194.104 41.224L186.808 48.776V69H168.888ZM229.748 35.72C229.748 16.264 241.14 0.519997 263.028 0.519997C284.916 0.519997 295.028 16.264 295.028 35.72C295.028 37.256 294.9 39.56 294.9 39.56H247.796C248.564 49.8 252.916 57.48 263.668 57.48C274.548 57.48 276.468 47.24 276.468 47.24H294.388C294.388 47.24 290.548 70.92 263.028 70.92C240.628 70.92 229.748 55.176 229.748 35.72ZM263.028 13.96C254.196 13.96 249.844 19.848 248.308 28.04H276.596C275.444 19.848 271.86 13.96 263.028 13.96Z"
        fill="currentColor"
      />
    </svg>
  ),
);
