import { useState, useEffect } from "react";
import { GetAvatarCategoryEnum } from "@/api/generated";
import { useAvatar } from "@/hooks/api/use-avatar";
import { useEvent } from "@/hooks/api/use-event";
import { EventDetailCard } from "@/types/event";
import { formatDate } from 'date-fns';

const DATE_FORMAT = "dd MMMM yyyy";

export const useEventData = (id?: string) => {
  const [data, setData] = useState<EventDetailCard>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { getEvent } = useEvent();
  const { byCategoryAndIdUrl } = useAvatar();

  const fetchData = async () => {
    if (id) {
      setIsLoading(() => true);
      const idNum = Number(id);
      const data = await getEvent(idNum);
      const avatarUrl = await byCategoryAndIdUrl(
        GetAvatarCategoryEnum.Event,
        idNum,
      );
      if (data) {
        const date = new Date(data.date);
        setData({
          ...data,
          avatar: avatarUrl,
          location: data.location,
          formattedDate: formatDate(date, DATE_FORMAT),
          time: formatDate(date, "HH:mm"),
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return {
    data,
    isLoading,
  };
};
