import React from "react";

import { EditEventCard } from '@/components/card/edit-event-card';

const EventEdit = () => {
  return (
    <div className="m-auto flex flex-col my-12 px-4">
      <EditEventCard />
    </div>
  );
};

export default EventEdit;
