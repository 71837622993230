import { SportControllerApi } from '@/api/generated';
import { useHttpClient } from '@/hooks/use-http-client';

export const useSport = () => {
    const httpClient = useHttpClient();

    const controller = new SportControllerApi(undefined, undefined, httpClient);

    const getAllSports = async () => {
        try {
            return (await controller.getAllSports()).data;
        } catch (error) {
            console.log(error);
            return [];
        }
    };

    return {
        getAllSports
    }
};
