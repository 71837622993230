import React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@/utils/tailwind";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startAdornment?: React.ReactNode;
  wrapperClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, wrapperClassName, startAdornment, type, value, ...props }, ref) => {
    return (
      <div className={cn("relative flex items-center", wrapperClassName)}>
        {!!startAdornment && (
          <Slot className="absolute left-4 top-4 bottom-0 m-auto">
            {startAdornment}
          </Slot>
        )}
        <input
          ref={ref}
          type={type}
          className={cn(
            `inline-flex w-full px-8 py-3 bg-white
          border rounded-xl border-blue-200
          focus:outline-none focus:placeholder-opacity-0 
          text-gray-200 placeholder-gray-200 ft1 transition-colors
          disabled:pointer-events-none disabled:opacity-50
          aria-invalid:[&:not(:focus)]:ring-1 aria-invalid:[&:not(:focus)]:ring-destructive aria-invalid:[&:not(:focus)]:text-destructive`,
            className
          )}
          defaultValue={value}
          {...props}
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
