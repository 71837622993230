import React from "react";

export const LockIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      stroke="#0B00BE"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <rect
        x="0.5"
        y="3.5"
        width="10"
        height="6"
        rx="1.5"
        stroke="currentColor"
      />
      <path
        d="M2.5 2C2.5 1.17157 3.17157 0.5 4 0.5H7C7.82843 0.5 8.5 1.17157 8.5 2V3.5H2.5V2Z"
        stroke="currentColor"
      />
      <path d="M5.5 6V7" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
);
