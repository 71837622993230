import {AvatarControllerApiAxiosParamCreator, GetAvatarCategoryEnum,} from "@/api/generated";
import {useMemo} from 'react';

export const useAvatar = () => {
  const urlParamsCreator = useMemo(() => AvatarControllerApiAxiosParamCreator(), []);

  const byCategoryAndIdUrl = async (category: GetAvatarCategoryEnum, id: number): Promise<string> => {
    if (!urlParamsCreator) {
      console.log('URL Params Creator not initialized');
      return '';
    }
    const urlParams = await urlParamsCreator.getAvatar(category, id);
    return process.env.REACT_APP_BASE_URL + urlParams.url;
  };

  return {
    byCategoryAndIdUrl,
  };
};
