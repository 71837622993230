import {useHttpClient} from '@/hooks/use-http-client';
import {AthleteControllerApi} from '@/api/generated';

export const useAthlete = () => {
  const httpClient = useHttpClient();

  const controller = new AthleteControllerApi(undefined, undefined, httpClient);

  const athleteMe = async () => {
    try {
      return (await controller.athleteMe()).data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    athleteMe
  }
};
