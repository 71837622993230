import {passwordValidation} from "@/utils/regex";
import {z} from "zod";

// TODO move custom error messages to global error map
export const loginSchema = z.object({
  email: z.string().email({message: "Некорректный email"}),
  password: z
    .string()
    .min(8, {message: "8 и более символов"})
    .regex(passwordValidation, {
      message:
        "Используйте строчные и прописные буквы, цифры и специальные символы",
    }),
});
