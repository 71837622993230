export const ERROR_MESSAGE = {
  required: 'Заполните поле',
  maxLength: 'Максимальное количество символов 30',
  invalidCreds: 'Неправильный логин или пароль',
  somethingWentWrong: 'Что то пошло не так',
  email: 'Некоректный адрес электронной почты',
  phone: 'Некоректный номер телефона',
  emailAlreadyExist: "Пользователь с таким email уже существует",
  invalidDate: 'Некорректный формат даты',
  invalidDistance: 'Дистанция должна быть больше 0',
};
