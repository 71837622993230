import React, { useEffect, useState } from "react";

export const Image = React.forwardRef(
  (
    { src, ...other }: React.ImgHTMLAttributes<HTMLImageElement>,
    ref: React.ForwardedRef<HTMLImageElement>
  ) => {
    const [imgSrc, setImgSrc] = useState(src);

    useEffect(() => {
      setImgSrc(src);
    }, [src])
    // eslint-disable-next-line jsx-a11y/alt-text
    return imgSrc ? <img ref={ref} src={imgSrc} onError={() => setImgSrc("")} {...other} /> : null;
  }
);
