import { Button } from "@/ui-kit/button";
import { InstagramIcon } from "@/icons/instagram";
import { TelegramIcon } from "@/icons/telegram";
import { EventSummary } from '@/components/event-summary';
import { ReviewCarousel } from '@/components/carousel/review-carousel';

const Event = () => {
  return (
    <div className="bg-white w-full content-padding-64">
      <EventSummary />
      <section className="flex flex-col">
        <div className="mt-8">
          <Button variant="outline">Событие с сайта</Button>
        </div>
        <div className="mt-5 flex gap-4">
          <p className="tt">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,
            natus. Ipsa deleniti odio accusamus beatae fuga fugiat nihil quia
            sit repellendus eveniet fugit officia, nemo doloribus? Maiores
            ducimus perferendis quas?
          </p>
          <div className="flex flex-col items-center px-6">
            <span className="h5">Поделиться</span>
            <div className="flex gap-2">
              <InstagramIcon />
              <TelegramIcon />
            </div>
          </div>
        </div>
        <div className="my-8 flex flex-wrap gap-4">
          <Button>Пригласить</Button>
          <Button variant="outlineMain">Событие на карте</Button>
        </div>
      </section>
      <section className="flex flex-col my-14">
        <h4 className="h4">Отзывы</h4>
        <div className='flex'>
        <ReviewCarousel />
        <Button variant="main" className="my-auto mx-8 h-auto">Добавить отзыв</Button>
        </div>
      </section>
    </div>
  );
};

export default Event;
