import { Button } from "@/ui-kit/button";
import { Form } from "@/ui-kit/form";
import { Input } from "@/components/rhf/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Dropdown } from "@/components/dropdown";
import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/ui-kit/collapsible";
import { ArrowIcon } from "@/icons/arrow";
import { DatePicker } from "@/components/rhf/date-picker";
import { eventSearchSchema } from "@/schemas/event.schema";
import { EventSearchParams } from "@/types/event";
import { eventsSearchParamsState } from "@/atoms/events/search-params";
import { LocationInput } from "@/components/rhf/location-input";
import { DEFAULT_LOCATION } from "@/consts/location";
import { SearchIcon } from "@/icons/search";
import { LocationIcon } from "@/icons/location";

export const EventsSearchWithFiltersForm = () => {
  const sportDictionary = useRecoilValue(sportDictionaryState);
  const setEventsSearchParams = useSetRecoilState(eventsSearchParamsState);
  const form = useForm<EventSearchParams>({
    resolver: zodResolver(eventSearchSchema),
  });

  const onSubmit = async (values: EventSearchParams) => {
    try {
      setEventsSearchParams(values);
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-4 mt-9">
          <div className="flex flex-1">
            <Input
              name="name"
              placeholder="Поиск"
              itemClassName="flex-1"
              controlElementClassName="border-r-0 rounded-r-none"
              startAdornment={<SearchIcon className="w-4 h-4 !top-0 !left-3" />}
            />
            <LocationInput
              name="location"
              itemClassName="flex-1"
              controlElementClassName="border-l-0 rounded-l-none"
              placeholder="Локация/Место"
              defaultMapCenterCoords={DEFAULT_LOCATION}
              startAdornment={
                <div className="!left-0 !top-0 h-full py-2 flex justify-center items-center gap-2">
                  <div className="w-px h-full border-l border-blue-200"></div>
                  <LocationIcon className="w-4 h-4 !left-3" />
                </div>
              }
            />
          </div>
          <Button className="m-auto" type="submit">
            Поиск
          </Button>
        </div>
        <Collapsible className="group text-center mt-2">
          <CollapsibleTrigger>
            <ArrowIcon className="group-data-[state=open]:rotate-180 transition-transform" />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="flex  flex-wrap gap-4">
              <Dropdown
                name="sportId"
                placeholder="Вид спорта"
                onChange={(selected) => {
                  form.setValue("sportId", selected?.value);
                }}
                options={Array.from(sportDictionary.values())}
                className="w-full md:w-1/4"
              />
              <div className="w-full md:w-1/4">
                <DatePicker
                  name="date"
                  placeholder="Дата"
                />
              </div>
              <Input
                name="distance"
                placeholder="Дистанция"
                itemClassName="w-full md:w-1/4"
              />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </form>
    </Form>
  );
};
