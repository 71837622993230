import { useEventList } from "@/hooks/list/use-event-list";
import { Skeleton } from "@/ui-kit/skeleton";
import { FC, useMemo } from "react";
import { EventCard } from "@/components/card/event-card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/ui-kit/carousel";
import { cn } from '@/utils/tailwind';

type EventsCarouselProps = {
    className?: string
}

export const EventsCarousel: FC<EventsCarouselProps> = ({ className }) => {
  const { data, isLoading } = useEventList();

  const carouselItems = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <CarouselItem key={index} className="basis-full flex justify-center sm:basis-1/2 xl:basis-1/3">
              <Skeleton
                key={index}
                className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
              />
            </CarouselItem>
          ))
        : data.map((event, index) => (
            <CarouselItem key={index} className="basis-full flex justify-center md:basis-1/2 xl:basis-1/3">
              <EventCard {...event} buttonText="Подробнее" className='!shadow-none w-full' />
            </CarouselItem>
          )),
    [data, isLoading],
  );

  return (
    <Carousel className={cn("w-full relative mb-10", className)}>
      <CarouselContent>
        {carouselItems}
      </CarouselContent>
      <div className='absolute -bottom-7 left-[50%] lg:left-[6%]'>
      <CarouselPrevious className='bg-black border-none text-white disabled:bg-gray-100 hover:bg-none hover:bg-gray-200' />
      <CarouselNext className='bg-black border-none text-white -right-8 disabled:bg-gray-100 hover:bg-none hover:bg-gray-200' />
      </div>
    </Carousel>
  );
};