import React from "react";

export const LinkIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M17.2994 13.35C17.037 13.3489 16.7856 13.2447 16.5994 13.06C16.4131 12.8726 16.3086 12.6192 16.3086 12.355C16.3086 12.0908 16.4131 11.8373 16.5994 11.65L18.7194 9.52998C18.9053 9.34423 19.0528 9.12365 19.1535 8.88086C19.2541 8.63806 19.3059 8.37781 19.3059 8.11498C19.3059 7.85215 19.2541 7.59189 19.1535 7.3491C19.0528 7.1063 18.9053 6.88572 18.7194 6.69998L17.2994 5.27998C16.9169 4.9185 16.4106 4.7171 15.8844 4.7171C15.3581 4.7171 14.8518 4.9185 14.4694 5.27998L12.3494 7.39998C12.1577 7.55335 11.9163 7.63085 11.6711 7.61771C11.426 7.60458 11.1942 7.50174 11.02 7.32877C10.8458 7.1558 10.7413 6.92479 10.7264 6.67975C10.7116 6.43471 10.7874 6.19275 10.9394 5.99998L13.0594 3.87998C13.822 3.15251 14.8354 2.74664 15.8894 2.74664C16.9433 2.74664 17.9567 3.15251 18.7194 3.87998L20.1294 5.28998C20.5013 5.66147 20.7963 6.10262 20.9976 6.58822C21.1989 7.07381 21.3025 7.59432 21.3025 8.11998C21.3025 8.64564 21.1989 9.16615 20.9976 9.65174C20.7963 10.1373 20.5013 10.5785 20.1294 10.95L17.9994 13.06C17.8131 13.2447 17.5617 13.3489 17.2994 13.35Z"
        fill="currentColor"
      />
      <path
        d="M8.11041 21.3C7.58497 21.3004 7.06459 21.1973 6.57901 20.9966C6.09343 20.7958 5.65216 20.5014 5.28041 20.13L3.87041 18.72C3.49851 18.3485 3.20347 17.9074 3.00217 17.4218C2.80088 16.9362 2.69727 16.4157 2.69727 15.89C2.69727 15.3644 2.80088 14.8439 3.00217 14.3583C3.20347 13.8727 3.49851 13.4315 3.87041 13.06L6.00041 10.94C6.19319 10.788 6.43514 10.7122 6.68018 10.7271C6.92522 10.742 7.15623 10.8465 7.3292 11.0207C7.50217 11.1949 7.60502 11.4266 7.61815 11.6718C7.63128 11.9169 7.55378 12.1583 7.40041 12.35L5.28041 14.47C5.09446 14.6558 4.94694 14.8763 4.84629 15.1191C4.74564 15.3619 4.69384 15.6222 4.69384 15.885C4.69384 16.1479 4.74564 16.4081 4.84629 16.6509C4.94694 16.8937 5.09446 17.1143 5.28041 17.3L6.70041 18.72C7.08287 19.0815 7.58916 19.2829 8.11541 19.2829C8.64166 19.2829 9.14796 19.0815 9.53041 18.72L11.6504 16.6C11.7377 16.4894 11.8473 16.3984 11.9722 16.3331C12.0971 16.2677 12.2344 16.2296 12.3751 16.221C12.5157 16.2125 12.6566 16.2338 12.7885 16.2835C12.9204 16.3332 13.0402 16.4103 13.1403 16.5096C13.2403 16.6089 13.3182 16.7282 13.3688 16.8597C13.4195 16.9912 13.4418 17.132 13.4342 17.2727C13.4267 17.4134 13.3895 17.551 13.3251 17.6763C13.2606 17.8017 13.1705 17.912 13.0604 18L10.9404 20.12C10.5695 20.4932 10.1286 20.7895 9.64296 20.992C9.15735 21.1944 8.63655 21.2991 8.11041 21.3Z"
        fill="currentColor"
      />
      <path
        d="M8.82025 16.18C8.68864 16.1808 8.55818 16.1555 8.43634 16.1058C8.31451 16.056 8.20369 15.9827 8.11025 15.89C8.01652 15.797 7.94213 15.6864 7.89136 15.5646C7.84059 15.4427 7.81445 15.312 7.81445 15.18C7.81445 15.048 7.84059 14.9173 7.89136 14.7954C7.94213 14.6736 8.01652 14.563 8.11025 14.47L14.4803 8.11001C14.6676 7.92376 14.9211 7.81921 15.1853 7.81921C15.4494 7.81921 15.7029 7.92376 15.8903 8.11001C15.984 8.20297 16.0584 8.31357 16.1091 8.43543C16.1599 8.55729 16.1861 8.68799 16.1861 8.82001C16.1861 8.95202 16.1599 9.08272 16.1091 9.20458C16.0584 9.32644 15.984 9.43704 15.8903 9.53001L9.52025 15.89C9.33399 16.0748 9.08259 16.1789 8.82025 16.18Z"
        fill="currentColor"
      />
    </svg>
  )
);
