import React from "react";

export const FacebookLoginIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_1589_7379)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5701 46H2.53898C1.13634 46 0 44.8629 0 43.461V2.53898C0 1.13634 1.13634 0 2.53898 0H43.4614C44.8633 0 46 1.13634 46 2.53898V43.461C46 44.8633 44.8633 46 43.4614 46H31.7393V28.1861H37.7186L38.6138 21.2437H31.7393V16.8116C31.7393 14.8016 32.2974 13.432 35.1796 13.432L38.8556 13.4306V7.22128C38.2199 7.13683 36.0378 6.9478 33.4988 6.9478C28.1984 6.9478 24.5697 10.1833 24.5697 16.1244V21.2441H18.5754V28.1865H24.5701V46Z"
          fill="#4460A0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_7379">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
