import React from "react";

export const VkLoginIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M2.56275 8.90466C3.90806 8.90466 5.95806 8.90466 7.11118 8.90466C7.68775 8.90466 8.13618 9.28904 8.32837 9.80154C8.90493 11.4672 10.1862 15.1187 11.6596 17.6172C13.6456 20.8844 14.9268 22.1015 15.7596 21.9734C16.5924 21.7812 16.3362 19.6031 16.3362 17.8734C16.3362 16.1437 16.5284 13.1969 15.6956 11.8515L14.4143 9.99373C14.094 9.54529 14.4143 8.96873 14.9268 8.96873H22.2299C22.9346 8.96873 23.5112 9.54529 23.5112 10.25V19.539C23.5112 19.539 23.8315 21.2047 25.6252 19.475C27.419 17.7453 29.3409 14.5422 30.9424 11.275L31.5831 9.73748C31.7752 9.28904 32.2237 8.96873 32.7362 8.96873H37.4768C38.3737 8.96873 39.0143 9.8656 38.694 10.6984L38.1815 12.0437C38.1815 12.0437 36.4518 15.5031 34.6581 17.9375C32.8643 20.4359 32.4799 21.0125 32.7362 21.6531C32.9924 22.2937 37.6049 26.5859 38.7581 28.6359C39.0784 29.2125 39.3346 29.725 39.5909 30.1734C40.0393 31.0062 39.3987 32.0953 38.4377 32.0953H33.0565C32.6081 32.0953 32.1596 31.839 31.9674 31.4547L31.4549 30.6219C31.4549 30.6219 28.1877 26.7781 26.2018 25.5609C24.1518 24.4078 24.2159 26.0734 24.2159 26.0734V29.4687C24.2159 30.8781 23.0627 32.0312 21.6534 32.0312H20.3721C20.3721 32.0312 13.3252 32.0312 7.68775 23.639C3.26743 17.1047 1.794 12.8765 1.2815 10.4422C1.15337 9.67341 1.72993 8.90466 2.56275 8.90466Z"
        fill="#5181B8"
      />
    </svg>
  )
);
