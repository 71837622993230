import React from "react";

export const CalendarStarIcon = React.forwardRef(
  (
    { ...other }: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => (
    <svg
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_2106_11615)">
        <path
          d="M19 2H18V1C18 0.448 17.552 0 17 0C16.448 0 16 0.448 16 1V2H8V1C8 0.448 7.552 0 7 0C6.448 0 6 0.448 6 1V2H5C2.243 2 0 4.243 0 7V19C0 21.757 2.243 24 5 24H19C21.757 24 24 21.757 24 19V7C24 4.243 21.757 2 19 2ZM5 4H19C20.654 4 22 5.346 22 7V8H2V7C2 5.346 3.346 4 5 4ZM19 22H5C3.346 22 2 20.654 2 19V10H22V19C22 20.654 20.654 22 19 22ZM16.397 15.352C16.397 15.731 16.133 16.05 15.831 16.218L14.267 17.088L14.961 18.981C15.095 19.348 14.974 19.759 14.662 19.994C14.343 20.234 13.903 20.231 13.587 19.987L12.031 18.784L10.475 19.987C10.159 20.231 9.719 20.234 9.4 19.994C9.088 19.759 8.967 19.348 9.101 18.981L9.795 17.088L8.231 16.218C7.929 16.05 7.665 15.731 7.665 15.352C7.665 15.031 7.944 14.676 8.396 14.676H10.643L11.239 12.393C11.333 12.031 11.658 11.779 12.031 11.772C12.404 11.779 12.729 12.031 12.823 12.393L13.419 14.676H15.666C16.118 14.676 16.397 15.03 16.397 15.352Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2106_11615">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
);
