import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import { SPORT_LABEL } from "@/consts/sports";
import { useSport } from "@/hooks/api/use-sport";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

type SportLabelKey = keyof typeof SPORT_LABEL
// TODO Not sure about this, it might be called in different scenarios, e.g registration, 
// however it's also needed in events list, posible solution to add check of recoil state size in order to avoid multiple requests
// should it return anything?
export const useSportDictionary = () => {
    const [sportDictionary, setSportDictionary] = useRecoilState(sportDictionaryState);
    const { getAllSports } = useSport();

    useEffect(() => {
        const fetchSports = async () => {
            const sportsList = await getAllSports();
            const sportsMap = new Map(
                sportsList.map(sport => [sport.id, { label: SPORT_LABEL[sport.name as SportLabelKey], name: sport.name, value: sport.id }])
            );
            setSportDictionary(sportsMap);
        };
        fetchSports();
    }, []);

    return sportDictionary;
}