import { Coords } from "@/types/map";
import { LatLng } from "leaflet";
import { FC } from "react";
import { Marker, Popup, useMapEvents } from "react-leaflet";

type LocationMarkerProps = {
  coords: Coords | null;
  onPositionChange?: (position: Coords) => void;
  popupText?: string;
};

export const LocationMarker: FC<LocationMarkerProps> = ({
  coords,
  onPositionChange,
  popupText: popup,
}) => {
  useMapEvents({
    click(e) {
      onPositionChange &&
        onPositionChange({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    },
  });

  const position = coords ? new LatLng(coords.latitude, coords.longitude) : null;

  return position && (
    <Marker position={position}>{popup && <Popup>{popup}</Popup>}</Marker>
  )
};
