import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
} from "@/ui-kit/carousel";
import { ReviewCard } from "@/components/card/review-card";

export const ReviewCarousel = () => {
  return (
    <Carousel className="mt-4 mb-12 relative">
      <CarouselContent>
        {Array.from({ length: 3 }).map((_, index) => (
          <CarouselItem key={index} className="basis-full lg:basis-1/2">
            <ReviewCard
              fullName="Иванов Иван Иванович"
              review="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut facere nihil, voluptatem quos voluptatum harum odio incidunt repudiandae sapiente repellendus magnam laudantium deserunt repellat atque hic itaque? Accusamus, quo obcaecati."
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDots />
    </Carousel>
  );
};
