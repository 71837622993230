import {Button} from "@/ui-kit/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/ui-kit/card";
import {Divider} from "@/ui-kit/divider";
import {Link} from "react-router-dom";
import {AuthProviders} from "@/components/auth-providers";
import RegisterForm from "@/components/forms/register";
import {useStepper} from "@/ui-kit/stepper";

export const RegisterCard = () => {
  const {nextStep} = useStepper();

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
      <CardHeader>
        <CardTitle>Регистрация</CardTitle>
      </CardHeader>
      <CardContent>
        <AuthProviders />
        <Divider>
          <span>или</span>
        </Divider>
        <RegisterForm onSubmitCallback={nextStep} />
      </CardContent>
      <CardFooter>
        <span>Есть аккаунт?</span>
        <Button variant="link" size="link" asChild>
          <Link to="/login">Войти</Link>
        </Button>
      </CardFooter>
    </Card>
  );
};
