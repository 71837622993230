import {Account} from "@/types/account";
import {atom} from "recoil";
import {AccountRole} from '@/types/account-role';

export const accountState = atom<Account>({
  key: "account",
  default: {
    id: 0,
    email: "",
    password: "",
    emailConfirmed: false,
    role: AccountRole.ATHLETE,
  },
});
