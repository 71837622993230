import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { CreateEventForm } from "@/components/forms/create-event";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/ui-kit/dialog";
import { Button } from "@/ui-kit/button";
import { Link } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/ui-kit/tabs";
import { LinkIcon } from "@/icons/link";

export const CreateEventCard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [eventId, setEventId] = useState<number | null>(null);

  const onSubmitHandler = (eventId?: number) => {
    setEventId(eventId || null);
    setOpenDialog(true);
  };

  return (
    <>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="px-24 pt-16 pb-12 max-w-2xl gap-2">
          <DialogHeader className="text-center sm:text-center">
            <DialogTitle className="h3">Ваше событие создано!</DialogTitle>
            <DialogDescription className="tt">
              В данный момент событие находится на модерации
            </DialogDescription>
          </DialogHeader>
          <div className="flex gap-1 justify-start">
            <span className="h5 flex items-center gap-1 justify-start">
              <LinkIcon />
              Ссылка:
            </span>
            <Button variant="link" asChild className="px-1">
              {eventId && (
                <Link
                  to={`/events/${eventId}`}
                >{`${window.location.origin}/events/${eventId}`}</Link>
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
        <CardHeader>
          <CardTitle>Создать событие</CardTitle>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="new" className="w-[400px]">
            <TabsList>
              <TabsTrigger value="new">Новое</TabsTrigger>
              <TabsTrigger value="external">Существующее</TabsTrigger>
            </TabsList>
            <TabsContent value="new">
              <CreateEventForm onSubmitCallback={onSubmitHandler} />
            </TabsContent>
            <TabsContent value="external">
              <CreateEventForm external onSubmitCallback={onSubmitHandler} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </>
  );
};
