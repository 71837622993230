import {LoginFormData} from "@/types/login";
import {useAccount} from "./api/use-account";
import {useRecoilState, useResetRecoilState} from "recoil";
import {hasTokenState} from "@/atoms/has-token";
import {accountState} from '@/atoms/account/account';
import {jwtDecode} from 'jwt-decode';
import {JwtToken} from '@/types/jwt-token';
import {useEffect} from 'react';

export const useAuth: () => {
  login: (creds: LoginFormData) => Promise<void>;
  logout: () => void;
} = () => {
  const {login} = useAccount();
  const [_hasToken, setHasToken] = useRecoilState(hasTokenState);
  const [_account, setAccount] = useRecoilState(accountState);
  const resetAccount = useResetRecoilState(accountState);
  const token = localStorage.getItem("accessToken");

  function onAuthSuccess(accessToken: string | null) {
    setHasToken(!!accessToken);
    if (accessToken) {
      const account = jwtDecode<JwtToken>(accessToken)['account'];
      console.log('user', account, 'logged in successfully')
      setAccount(account);
    }
  }

  useEffect(() => onAuthSuccess(token), []);

  return {
    login: async (creds: LoginFormData) => {
      const {
        accessToken,
      } = await login(creds);
      if (!accessToken) {
        throw new Error("Неверный логин или пароль");
      }
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        onAuthSuccess(accessToken);
      }
    },
    logout: () => {
      localStorage.removeItem("accessToken")
      console.log('user logged out successfully')
      setHasToken(false);
      resetAccount();
    },
  };
};
