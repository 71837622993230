import React from "react";

export const EyeIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#0B00BE"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M7 11.1427C3.70562 11.1427 1.13312 7.40644 1.02375 7.24457L0.875 6.99957L1.04125 6.75457C1.13313 6.5927 3.70562 2.85645 7 2.85645C10.2944 2.85645 12.8669 6.5927 12.9762 6.75457L13.125 6.99957L12.9588 7.24457C12.8669 7.40644 10.2944 11.1427 7 11.1427ZM1.92937 6.99957C2.5375 7.79582 4.61563 10.2677 7 10.2677C9.38437 10.2677 11.4625 7.8002 12.0706 6.99957C11.4625 6.20332 9.38437 3.73145 7 3.73145C4.61563 3.73145 2.5375 6.19895 1.92937 6.99957Z"
        fill="currentColor"
      />
      <path
        d="M7 4.81262C6.93006 4.80855 6.85994 4.80855 6.79 4.81262C6.75361 4.81583 6.71967 4.83232 6.69466 4.85895C6.66965 4.88557 6.65531 4.92047 6.65437 4.95699C6.65447 4.99641 6.67021 5.03417 6.69813 5.06199C6.90431 5.27242 7.02044 5.55489 7.02187 5.84949C7.02187 6.15466 6.90065 6.44732 6.68486 6.66311C6.46908 6.87889 6.17641 7.00012 5.87125 7.00012C5.58081 6.99922 5.30189 6.8864 5.0925 6.68512C5.07301 6.66437 5.04813 6.64946 5.02064 6.64207C4.99315 6.63468 4.96415 6.6351 4.93689 6.64328C4.90962 6.65146 4.88518 6.66708 4.8663 6.68838C4.84742 6.70968 4.83485 6.73582 4.83 6.76387C4.819 6.84215 4.81316 6.92107 4.8125 7.00012C4.8125 7.43276 4.94079 7.85569 5.18116 8.21543C5.42153 8.57516 5.76317 8.85554 6.16288 9.0211C6.56259 9.18667 7.00243 9.22999 7.42676 9.14558C7.85109 9.06118 8.24087 8.85284 8.5468 8.54691C8.85272 8.24099 9.06106 7.85121 9.14547 7.42688C9.22987 7.00254 9.18655 6.56271 9.02099 6.163C8.85542 5.76328 8.57504 5.42164 8.21531 5.18128C7.85558 4.94091 7.43265 4.81262 7 4.81262Z"
        fill="currentColor"
      />
    </svg>
  )
);
