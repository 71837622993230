import { forwardRef, useState } from "react";
import { EyeIcon } from "@/icons/eye";
import { EyeOffIcon } from "@/icons/eye-off";
import { Input, InputProps } from "@/ui-kit/input";
import { cn } from "@/utils/tailwind";

const InputWithVisibilityToggle = forwardRef<HTMLInputElement, InputProps>(
  ({ type, className, ...other }, ref) => {
    const [inputType, setInputType] = useState(type || "text");
    const valid = !other["aria-invalid"];
    const toggleType = () => {
      setInputType((prev) => (prev === "password" ? "text" : "password"));
    };

    return (
      <div className="relative">
        <Input
          ref={ref}
          type={inputType}
          {...other}
          className={cn("w-full", className)}
        />
        {valid && type === "password" && (
          <button
            type="button"
            className="absolute right-4 top-8"
            onClick={toggleType}
          >
            {inputType === "password" ? (
              <EyeOffIcon className="text-primary" />
            ) : (
              <EyeIcon className="text-primary" />
            )}
          </button>
        )}
      </div>
    );
  }
);

InputWithVisibilityToggle.displayName = "InputWithVisibilityToggle";

export default InputWithVisibilityToggle;
