import {useAthlete} from '@/hooks/api/use-athlete';
import {useOrganization} from '@/hooks/api/use-organization';
import {useRecoilValue} from 'recoil';
import {useEffect, useState} from 'react';
import {Athlete, Organization} from '@/api/generated';
import {accountState} from '@/atoms/account/account';
import {AccountRole} from '@/types/account-role';

const abbr = (value: string | undefined) => value?.at(0)?.toUpperCase() ?? "";

export const useMe: () => {
  getNameAbbr: () => string
} = () => {
  const account = useRecoilValue(accountState);
  const {athleteMe} = useAthlete();
  const {organizationMe} = useOrganization();
  const [athlete, setAthlete] = useState<Athlete>();
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    if (account.id) {
      const fetchAthleteOrOrganization = async () => {
        if (account.role === AccountRole.ORGANIZER) {
          setOrganization(await organizationMe());
        } else {
          setAthlete(await athleteMe());
        }
      };
      fetchAthleteOrOrganization()
    }
  }, [account.id, account.role]);

  return {
    getNameAbbr: () => {
      if (account.role === AccountRole.ORGANIZER) {
        return abbr(organization?.name);
      } else {
        return abbr(athlete?.firstName) + abbr(athlete?.lastName);
      }
    }
  }
};
