import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";

import {GetAvatarCategoryEnum} from "@/api/generated";
import {PlusIcon} from "@/icons/plus";
import {LogoImage} from "@/icons/logo";
import {Button} from "@/ui-kit/button";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,} from "@/ui-kit/tooltip";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,} from "@/ui-kit/dropdown";
import {useAvatar} from "@/hooks/api/use-avatar";
import {useAuth} from "@/hooks/use-auth";
import {accountState} from "@/atoms/account/account";
import {useMe} from '@/hooks/use-me';
import {Avatar, AvatarFallback, AvatarImage} from '@/ui-kit/avatar';

export const Header = () => {
  const {byCategoryAndIdUrl} = useAvatar();
  const {logout} = useAuth();
  const account = useRecoilValue(accountState);
  const {getNameAbbr} = useMe();
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [nameAbbr, setNameAbbr] = useState<string>('');

  useEffect(() => {
    if (account.id) {
      const loadLink = async () => {
        setAvatarUrl(await byCategoryAndIdUrl(GetAvatarCategoryEnum.Account, account.id));
      }
      loadLink();
      setNameAbbr(getNameAbbr());
    }
  }, [account.id, byCategoryAndIdUrl, getNameAbbr]);

  return (
    <header className="content-padding-64 py-6 flex justify-between items-center flex-wrap shadow-header z-10">
      <Link to="/">
        <LogoImage/>
      </Link>
      <div className="flex gap-4">
        <Button variant="ghost" size="sm" asChild>
          <Link to="/events">События</Link>
        </Button>
        <Button variant="ghost" size="sm" asChild>
          <Link to="/athletes">Люди</Link>
        </Button>
        <Button variant="outline" size="sm">
          Расскажи о нас
        </Button>
      </div>
      <div className="flex gap-4">
        {account.id ? (
          <>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant={"ghost"} size={"icon"} asChild>
                    <Link to="/events/create">
                      <PlusIcon/>
                    </Link>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Добавить событие</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className='cursor-pointer'>
                  <AvatarImage src={avatarUrl}/>
                  <AvatarFallback>{nameAbbr}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuItem>
                  <Link to="/profile">
                    <span>Мой профиль</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Link to="/" onClick={logout}>
                    <span>Выйти</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ) : (
          <Button variant="outline" size="sm" asChild>
            <Link to="/login">Войти</Link>
          </Button>
        )}
        <Button variant="ghost" size="sm">
          EN
        </Button>
      </div>
    </header>
  );
};
