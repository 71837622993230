import {FC, InputHTMLAttributes} from "react";
import {useFormContext} from "react-hook-form";
import InputWithVisibilityToggle from "@/components/input-with-visibility-toggle";
import {ReactHookFormField} from "@/types/rhf";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/ui-kit/form";
import {Input as UIKitInput} from "@/ui-kit/input";
import {InputFile} from "@/components/input-file";

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "name">,
    ReactHookFormField {
  withVisibilityToggle?: boolean;
  onValueChange?: (value: string | FileList) => void;
  startAdornment?: React.ReactNode;
}

export const Input: FC<InputProps> = ({
  name,
  disabled,
  itemClassName,
  label,
  labelClassName,
  placeholder = "",
  controlElementClassName,
  messageClassName,
  withVisibilityToggle,
  onValueChange,
  startAdornment,
  type = "text",
}) => {
  const {control} = useFormContext();
  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({field: {onChange, ...other}}) => (
        <FormItem className={itemClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            {type === "file" ? (
                <InputFile onChange={(files) => {
                  console.log('file: ', files);
                  onChange(files);
                  onValueChange && onValueChange(files);
                }} />
            ) : withVisibilityToggle ? (
              <InputWithVisibilityToggle
                placeholder={placeholder}
                type="password"
                className={controlElementClassName}
                onChange={(event) => {
                  onChange(event);
                  onValueChange && onValueChange(event.target.value);
                }}
                startAdornment={startAdornment}
                {...other}
              />
            ) : (
              <UIKitInput
                placeholder={placeholder}
                className={controlElementClassName}
                onChange={(event) => {
                  onChange(event);
                  onValueChange && onValueChange(event.target.value);
                }}
                startAdornment={startAdornment}
                {...other}
              />
            )}
          </FormControl>

          <FormMessage className={messageClassName} />
        </FormItem>
      )}
    />
  );
};

export type {InputProps};
