import { useEventList } from "@/hooks/list/use-event-list";
import { useMemo } from "react";
import { EventCard } from "@/components/card/event-card";
import { Pagination } from "@/components/pagination";
import { Skeleton } from "@/ui-kit/skeleton";

export const EventList = () => {
  const { data, isLoading, page, setPage, setPageSize } = useEventList();

  const events = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
            />
          ))
        : data.map((event, index) => (
            <EventCard key={index} {...event} buttonText="Подробнее" />
          )),
    [data, isLoading],
  );

  return (
    <div className="content-padding-64 md:content-padding-56 mt-12">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          <h4 className="h4">События</h4>
          <span className="ct text-gray-100 mb-[0.3rem]">
            Найдено: {data.length}
          </span>
        </div>
        <div>Фильтр</div>
      </div>
      <>
        <div className="flex flex-wrap gap-2 justify-center md:justify-start my-8 min-h-96">
          {events}
        </div>
        <Pagination total={10} onChange={setPage} defaultCurrent={page} />
      </>
    </div>
  );
};
