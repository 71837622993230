import { EventList } from "@/components/list/event-list";
import { useSportDictionary } from "@/hooks/use-sport-dictionary";
import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";

const Events = () => {
  useSportDictionary();

  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <EventList />
    </div>
  );
};

export default Events;
