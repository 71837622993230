import { Avatar, AvatarFallback, AvatarImage } from "@/ui-kit/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { FC } from "react";

type ReviewCardProps = {
  avatarUrl?: string;
  fullName?: string;
  review?: string;
};

export const ReviewCard: FC<ReviewCardProps> = ({
  avatarUrl,
  fullName,
  review,
}) => {
  return (
    <Card className="shadow-button rounded-2xl bg-gradient-to-b from-green-200 from-50% to-green-300 ">
      <CardHeader className="flex-row gap-2">
        <Avatar className="bg-green-950 rounded-full text-white">
          <AvatarImage src={avatarUrl} />
          <AvatarFallback>A</AvatarFallback>
        </Avatar>
        <CardTitle className="text-white">
          <span>{fullName}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-6 text-white">
        <span>{review}</span>
      </CardContent>
    </Card>
  );
};