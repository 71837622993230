import {FC, ReactElement} from "react";
import {Navigate} from "react-router-dom";
import {useRecoilValue} from 'recoil';
import {hasTokenState} from '@/atoms/has-token';

export const ProtectedRoute: FC<{ children?: React.ReactNode }> = ({children}) => {
  const hasToken = useRecoilValue(hasTokenState);
  if (!hasToken) {
    return <Navigate to="/login" replace/>;
  }
  return children as ReactElement;
};
