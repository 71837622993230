import React from "react";

export const EyeOffIcon = React.forwardRef(
  (
    {...other}: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#0B00BE"
      stroke="#0B00BE"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_755_122)">
        <path
          d="M3.3425 9.6902L3.96812 9.06019C3.20411 8.46233 2.51905 7.76992 1.92937 6.99957C2.5375 6.19895 4.61125 3.73145 7 3.73145C7.63174 3.73941 8.25366 3.88891 8.82 4.16895L9.4675 3.51707C8.71193 3.09707 7.86435 2.87015 7 2.85645C3.70562 2.85645 1.13312 6.5927 1.02375 6.75457L0.875 6.99957L1.04125 7.24457C1.69023 8.16335 2.46486 8.98657 3.3425 9.6902Z"
          fill="currentColor"
        />
        <path
          d="M12.9761 6.75523C12.3536 5.88566 11.618 5.10294 10.7886 4.42773L10.163 5.05336C10.8733 5.62497 11.5135 6.27845 12.0705 7.00023C11.4624 7.80086 9.38863 10.2684 6.99988 10.2684C6.42527 10.2585 5.85903 10.1289 5.33738 9.88773L4.67676 10.5484C5.39381 10.926 6.18958 11.1298 6.99988 11.1434C10.2943 11.1434 12.8668 7.40711 12.9761 7.24523L13.1249 7.00023L12.9761 6.75523Z"
          fill="currentColor"
        />
        <path
          d="M10.1342 2.61674L2.61991 10.1311C2.44905 10.3019 2.44905 10.5789 2.61991 10.7498C2.79076 10.9206 3.06777 10.9206 3.23862 10.7498L10.753 3.23545C10.9238 3.0646 10.9238 2.78759 10.753 2.61674C10.5821 2.44588 10.3051 2.44588 10.1342 2.61674Z"
          fill="currentColor"
        />
        <path
          d="M8.36914 5.30664L5.30664 8.36914C5.49469 8.61987 5.73441 8.82726 6.00958 8.97727C6.28476 9.12729 6.58895 9.21642 6.90157 9.23864C7.21419 9.26085 7.52793 9.21564 7.82156 9.10605C8.11518 8.99645 8.38182 8.82505 8.60344 8.60344C8.82505 8.38182 8.99645 8.11518 9.10605 7.82156C9.21564 7.52793 9.26085 7.21419 9.23864 6.90157C9.21642 6.58895 9.12729 6.28476 8.97727 6.00958C8.82726 5.73441 8.61987 5.49469 8.36914 5.30664Z"
          fill="currentColor"
        />
        <path
          d="M2.07812 6.16016L4.6025 8.68453"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M2.72559 5.53418L5.24996 8.05855"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.35547 4.90918L5.87984 7.43355"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M3.97266 4.27832L6.49703 6.8027"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M4.61133 3.65332L7.1357 6.1777"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M5.7666 3.56543L7.74848 5.5473"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M6.74609 3.29883L8.36484 4.92195"
          stroke="currentColor"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_755_122">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
