import { ERROR_MESSAGE } from '@/consts/errors';
import {z} from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  return {
    message:
      ctx.defaultError === 'Required'
        ? ERROR_MESSAGE.required
        : ctx.defaultError,
  };
};

z.setErrorMap(customErrorMap);
