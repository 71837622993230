import React from "react";

export const CalendarIcon = React.forwardRef(
  (
    { ...other }: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>
  ) => (
    <svg
      ref={ref}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <g clipPath="url(#clip0_2315_10770)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10.6923 4.76923H10.4231V4.50757C10.4231 4.36269 10.3117 4.23834 10.1668 4.23111C10.1304 4.22935 10.094 4.23501 10.0599 4.24773C10.0257 4.26045 9.9945 4.27997 9.96812 4.30511C9.94173 4.33026 9.92073 4.36049 9.90637 4.39399C9.89202 4.42749 9.88462 4.46356 9.88462 4.5V4.76923H6.11538V4.50757C6.11538 4.36269 6.00399 4.23834 5.85911 4.23111C5.82271 4.22935 5.78633 4.23501 5.75218 4.24773C5.71802 4.26045 5.68681 4.27997 5.66043 4.30511C5.63404 4.33026 5.61304 4.36049 5.59868 4.39399C5.58433 4.42749 5.57693 4.46356 5.57692 4.5V4.76923H5.30769C5.02207 4.76923 4.74816 4.88269 4.54619 5.08465C4.34423 5.28662 4.23077 5.56054 4.23077 5.84615V6.04808C4.23077 6.06593 4.23786 6.08305 4.25048 6.09567C4.26311 6.10829 4.28023 6.11538 4.29808 6.11538H11.7019C11.7198 6.11538 11.7369 6.10829 11.7495 6.09567C11.7621 6.08305 11.7692 6.06593 11.7692 6.04808V5.84615C11.7692 5.56054 11.6558 5.28662 11.4538 5.08465C11.2518 4.88269 10.9779 4.76923 10.6923 4.76923ZM4.24555 6.66863C4.25502 6.65916 4.26786 6.65385 4.28125 6.65385H11.7188C11.7321 6.65385 11.745 6.65916 11.7544 6.66863C11.7639 6.6781 11.7692 6.69094 11.7692 6.70433V10.6923C11.7692 10.9779 11.6558 11.2518 11.4538 11.4538C11.2518 11.6558 10.9779 11.7692 10.6923 11.7692H5.30769C5.02207 11.7692 4.74816 11.6558 4.54619 11.4538C4.34423 11.2518 4.23077 10.9779 4.23077 10.6923V6.70433C4.23077 6.69094 4.23609 6.6781 4.24555 6.66863ZM7.32073 9.20535C7.41089 9.11519 7.46154 8.99291 7.46154 8.86541V7.67305C7.46154 7.54555 7.41089 7.42327 7.32073 7.33312C7.23057 7.24296 7.10829 7.19231 6.98079 7.19231H5.78844C5.66094 7.19231 5.53866 7.24296 5.4485 7.33312C5.35834 7.42327 5.30769 7.54555 5.30769 7.67305V8.86541C5.30769 8.99291 5.35834 9.11519 5.4485 9.20535C5.53866 9.2955 5.66094 9.34615 5.78844 9.34615H6.98079C7.10829 9.34615 7.23057 9.2955 7.32073 9.20535Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2315_10770">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
