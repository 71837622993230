import { FC, useState, useEffect } from "react";
import Cropper, { Area } from "react-easy-crop";

import { PlusIcon } from "@/icons/plus";
import { Image } from "@/icons/image";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogHeader,
  DialogClose,
} from "@/ui-kit/dialog";
import { Button } from "@/ui-kit/button";
import { getCroppedImg, getRotatedImage } from "@/utils/canvas";
import { readFile } from "@/utils/fs";
import { cn } from "@/utils/tailwind";

type InputFileProps = {
  onChange?: (image: string) => void;
};

export const InputFile: FC<InputFileProps> = ({ onChange }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  useEffect(() => {
    showCroppedImage();
  }, [croppedAreaPixels]);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    if (imageSrc) {
      try {
        const croppedImage = await getCroppedImg(
          imageSrc.toString(),
          croppedAreaPixels,
          rotation
        );

        croppedImage && setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      try {
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, 0);
        }
      } catch (e) {
        console.warn("failed to detect the orientation");
      }

      setImageSrc(imageDataUrl?.toString() || null);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
    setOpenDialog(false);
  };

  const onSave = () => {
    if (croppedImage) {
      onChange?.(croppedImage);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={openDialog}>
        <DialogContent className="min-w-[600px]">
          <DialogHeader>
            <DialogTitle>Добавить изображение профиля</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <div className="flex gap-8">
            <div className="min-h-80  min-w-80 overflow-hidden relative">
              <Cropper
                image={imageSrc!}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            {croppedImage && (
              <Image
                src={croppedImage ?? ""}
                className="w-[30%] self-start shadow-md mx-auto"
                alt="cropped image"
              />
            )}
          </div>
          <div className="flex flex-wrap gap-4 justify-center pt-4 pb-10">
            <Button variant="main" onClick={onSave}>
              Сохранить
            </Button>
            <Button variant="outlineMain" onClick={onClose}>
              Отмена
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <div className={cn("flex flex-row justify-center items-center")}>
        <input
          type="file"
          id="custom-input"
          accept="image/jpg, image/jpeg, image/png"
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            onFileChange({ ...e, target });
            setOpenDialog(true);
          }}
          hidden
        />
        <label
          htmlFor="custom-input"
          className={cn(
            "block b4 px-6 py-10 font-semibold border border-blue-200 hover:bg-gray-600/25 rounded-xl cursor-pointer",
            {
              "bg-neutral-950/25": croppedImage,
            }
          )}
        >
          <PlusIcon className="w-6 h-6" />
        </label>
      </div>
    </>
  );
};
