import {FC, HTMLAttributes} from "react";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import {Input} from "@/components/rhf/input";
import {Button} from "@/ui-kit/button";
import {Form, FormMessage} from "@/ui-kit/form";
import {MessageIcon} from "@/icons/message";
import {LockIcon} from "@/icons/lock";
import {CheckboxWithText} from "@/components/checkbox-with-text";
import {loginSchema} from "@/schemas/login.schema";
import {LoginFormData} from "@/types/login";
import {useAuth} from "@/hooks/use-auth";
import {useNavigate} from "react-router-dom";

interface LoginFormProps extends HTMLAttributes<HTMLElement> {
  callbackUrl?: string;
  error?: string;
}

const LoginForm: FC<LoginFormProps> = () => {
  const {login} = useAuth();
  const navigate = useNavigate();
  const form = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (values: LoginFormData) => {
    try {
      await login(values);
      navigate("/events");
    } catch (error: any) {
      form.setError("root", {message: error.message});
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          name="email"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Email"
          startAdornment={<MessageIcon className="text-primary" />}
        />
        <Input
          withVisibilityToggle
          name="password"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Введите пароль"
          startAdornment={<LockIcon className="text-primary" />}
        />
        <div className="flex justify-between ft1">
          <CheckboxWithText children="Запомнить меня" name="remember" />
          <Button type="button" variant="ghost" size="sm" className="ft1">
            Забыли пароль?
          </Button>
        </div>
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <div className="flex justify-center">
          <Button type="submit">Войти</Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
