import {useStepper} from "@/ui-kit/stepper";

import TrainerImage from "@/images/trainer.png";
import AthleteImage from "@/images/athlete.png";
import OrganizerImage from "@/images/organizer.png";
import {AccountRoleCard} from "@/components/card/account-role-card";
import {accountRoleState} from "@/atoms/account/account-role";
import { useRecoilState } from "recoil";
import {AccountRole} from '@/types/account-role';

const pros = ["Приемущества", "Приемущества", "Приемущества", "Приемущества"];

export const AccountRoleCards = () => {
  const {nextStep} = useStepper();
  const [_accountRole, setAccountRole] = useRecoilState(accountRoleState);

  const onClickHandler = (name: AccountRole) => {
    setAccountRole(name);
    nextStep();
  };

  return (
    <div className="flex flex-col">
      <div className="text-white py-10 m-auto">
        <h2 className="h2">Тип пользователя:</h2>
        <span className="h6">Выбор всегда можно изменить в настройках</span>
      </div>
      <div className="flex gap-8 flex-wrap mb-32">
        <AccountRoleCard
          title="Тренер"
          image={TrainerImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.TRAINER)}
        />
        <AccountRoleCard
          primary
          title="Спортсмен"
          image={AthleteImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.ATHLETE)}
        />
        <AccountRoleCard
          title="Организатор"
          image={OrganizerImage}
          description="Подойдёт для тех, кто:"
          pros={pros}
          onClickHandler={() => onClickHandler(AccountRole.ORGANIZER)}
        />
      </div>
    </div>
  );
};
